import React, { useEffect, useState } from "react";
import { Card, Col, Container } from "react-bootstrap";
import "./PerformanceRate.css";
import { Row } from "react-bootstrap";
import trendImg from "../../assests/trend.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { Slide, LightSpeed } from "react-reveal";

function PerformanceRate() {
  const [dataRate, setDataRate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/performance-rate/recommendation-type`,
        headers: "application/json",
      });
      setDataRate(result.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="performance-rate-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>معدل الأداء</span>
          </h5>
        </div>
      </div>
      <div className="performance-rate">
        <Container className="container-body">
          <Container>
            <Container>
              <LightSpeed>
                <h4>معدل الأداء</h4>
              </LightSpeed>
              {loading ? (
                <div className="loading text-center">
                  <div className="spinner-grow text-dark" role="status"></div>
                  <div
                    className="spinner-grow text-warning"
                    role="status"
                  ></div>
                  <div className="spinner-grow text-dark" role="status"></div>
                </div>
              ) : (
                <Row>
                  {dataRate.map((item) => (
                    <Col xs={12} md={6} key={item.id}>
                      <Slide right>
                        <Card className="d-flex flex-column mb-3 perf-card">
                          <img src={trendImg} alt="" />
                          <Card.Body>
                            <Card.Title className="title-num text-center">
                              {item.name}
                            </Card.Title>
                            <Card.Text className="text-num text-center py-2">
                              {item.description}
                            </Card.Text>
                          </Card.Body>
                          <Link to={`/performance-details/${item.id}`}>
                            <button
                              onClick={() =>
                                window.scrollTo({ top: 0, behavior: "smooth" })
                              }
                            >
                              استعراض معدل الأداء
                            </button>
                          </Link>
                        </Card>
                      </Slide>
                    </Col>
                  ))}
                </Row>
              )}
            </Container>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default PerformanceRate;
