import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Carosel.css";
import axios from "axios";

function Carosel() {
  const [carouselData, setCarouselData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/slider`,
        headers: "application/json",
      });
      setCarouselData(result.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading-carsoul loading text-center">
          <div className="spinner-grow text-dark" role="status"></div>
          <div className="spinner-grow text-warning" role="status"></div>
          <div className="spinner-grow text-dark" role="status"></div>
        </div>
      ) : (
        <Carousel interval="3000" className="carosel" fade>
          {carouselData.map((item) => (
            <Carousel.Item key={item.id}>
              <img
                className="d-block w-100"
                src={item.image}
                alt="First slide"
              />
              <Carousel.Caption className="slide-text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
}

export default Carosel;
