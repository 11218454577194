import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Pointer.css";
import { Slide, LightSpeed } from "react-reveal";

function Pointer() {
  const [pointerData, setPointerData] = useState({});
  const [loading, setLoading] = useState(false);


  const marketId = localStorage.getItem("thiqa-marketId")

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/pointer/${marketId}`,
        headers: "application/json",
      });
      setPointerData(result.data.data);
      setLoading(false);
    })();
  }, [marketId]);
  return (
    <div className="pointer">
      <LightSpeed>
        <h4>المــؤشـر</h4>
      </LightSpeed>
      {loading ? (
        <div className="loading text-center">
          <div className="spinner-grow text-dark" role="status"></div>
          <div className="spinner-grow text-warning" role="status"></div>
          <div className="spinner-grow text-dark" role="status"></div>
        </div>
      ) : (
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col xs={12}>
              <Slide right>
                <div className="card pointer-card flex-column">
                  <img src={pointerData.image} alt="" />
                  <p>{pointerData.description}</p>
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Pointer;
