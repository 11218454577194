import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./NewsDetails.css";
import { Container } from "react-bootstrap";

function NewsDetails() {
  const [newsInfo, setNewsInfo] = useState([]);

  const { newsId } = useParams();

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/news/show/${newsId}`,
        headers: "application/json",
      });
      setNewsInfo(result.data.data);
    })();
  }, []);

  return (
    <>
      <div className="performance-details-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            /
            <Link className="back-home" to="/news">
              الاخبار
            </Link> 
            / <span>تفاصيل الخبر</span>
          </h5>
        </div>
      </div>
      <div className="news-details">
        <Container>
          <h5>تفاصيل الخبر</h5>
          <div className="card flex-column">
            <img
              style={{ width: "100%", height: "400px" }}
              src={newsInfo.image}
              alt=""
            />

            <h3>{newsInfo.title}</h3>
            <p>{newsInfo.description}</p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default NewsDetails;
