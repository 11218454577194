import React, { useState, useEffect } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import "./AdvantageTrust.css";
import { Slide, LightSpeed } from "react-reveal";
import axios from "axios";

function AdvantageTrust() {
  const [advantageData, setAdvantageData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/features`,
        headers: "application/json",
      });

      const iconsArr = [
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
      ];

      const arrNew = result.data.data.map((object, i) => {
        return { ...object, icon: iconsArr[i] };
      });
      setAdvantageData(arrNew);

      setLoading(false);
    })();
  }, []);

  return (
    <div className="advantage-trust" id="about">
      <Container>
        <LightSpeed>
          <h4>مميزات ثقة</h4>
        </LightSpeed>

        {loading ? (
          <div className="loading text-center">
            <div className="spinner-grow text-dark" role="status"></div>
            <div className="spinner-grow text-warning" role="status"></div>
            <div className="spinner-grow text-dark" role="status"></div>
          </div>
        ) : (
          <Row>
            <p className="private-text">بعض من مميزات ثقة علي سبيل المثال</p>
            {advantageData.map((item) => (
              <Col xs={12} md={6} lg={4} key={item.id}>
                <Slide right>
                  <div className="card services-card">
                    <i className={`${item.icon} advantage-icon`}></i>

                    <div>
                      <h6>{item.name}</h6>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </Slide>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
}

export default AdvantageTrust;
