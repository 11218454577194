import React from 'react'
import "./CopyRight.css"

function CopyRight() {
  return (
      <div className="copy-right">
          <h6>جميع الحقوق محفوظة لدى ثقة 2023</h6>
    </div>
  )
}

export default CopyRight