import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import logo from "../../../assests/black-logo.png";
import logo2 from "../../../assests/black-logo2.png";
import "./NavBarLogin.css";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import Modall from "../../Modal/Modal";
import { MdMenu } from "react-icons/md";
import axios from "axios";

function NavBarLogin({ setIsLoggedIn, setRefresh, refresh }) {
  const [show, setShow] = useState(false);

  const [activeLink, setActiveLink] = useState(1);

  const handleShow = () => setShow(true);

  const [marketInfo, setMarketInfo] = useState([]);

  const [marketName, setMarketName] = useState("");

  const [selected, setSelected] = useState();
  const [navbar, setNavbar] = useState(false);

  const [hide, setHide] = useState(true);

  const handleHide = () => setHide(!hide);

  const handleClickToggle = () => {
    setHide(!hide);
  };

  const handleMarketChange = (e) => {
    setSelected(e);
    setRefresh(!refresh);
  };

  localStorage.setItem("thiqa-marketId", selected);

  const changeNavColor = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeNavColor();
    window.addEventListener("scroll", changeNavColor);
  });

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/markets`,
        headers: "application/json",
      });

      const thiqaMarkets = result.data.data.filter((item) => item.web_id === 1);

      setMarketInfo(thiqaMarkets);
      setSelected(result.data.data[0]?.id);
    })();
  }, []);


  return (
    <Navbar
      style={{ backgroundColor: navbar ? "#090b28" : "white" }}
      className={navbar ? "navbar-trust" : "navbar-trust  change-links"}
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container>
        <Link
          className="logo-link"
          to="/"
          onClick={() => {
            setHide(!hide);
            setActiveLink(1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img className="logo" src={navbar ? logo : logo2} alt="" />
        </Link>
        <MdMenu onClick={handleClickToggle} className="toggle-btn" />
        <Navbar.Collapse className={hide ? "hide" : "show"}>
          <Nav className="links-route">
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 1 ? "active-link" : "links"}
                to="/"
              >
                الرئيسية
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(2);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 2 ? "active-link" : "links"}
                to="/packges"
              >
                الباقات
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(3);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 3 ? "active-link" : "links"}
                to="/performance-rate"
              >
                معدل الأداء
              </Link>
            </div>
            {/* <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(4);
                }}
                className={activeLink === 4 ? "active-link" : "links"}
                to="/your-issue"
              >
                مشكلتك
              </Link>
            </div> */}
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(5);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 5 ? "active-link" : "links"}
                to="/trust-rate"
              >
                نسبة ثقة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(6);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 6 ? "active-link" : "links"}
                to="/services"
              >
                الخدمات
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(7);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 7 ? "active-link" : "links"}
                to="/news"
              >
                الاخبار
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(8);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 8 ? "active-link" : "links"}
                to="/about-us"
              >
                عن ثقة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(4);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 4 ? "active-link" : "links"}
                to="/training"
              >
                تدريب
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(9);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 9 ? "active-link" : "links"}
                to="/competition"
              >
                المنافسة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(10);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 10 ? "active-link" : "links"}
                to="/contact-us"
              >
                اتصل بنا
              </Link>
            </div>
          </Nav>
          <Nav className="links-route-btns">
            <div className="flags ms-3">
              <Dropdown>
                <Dropdown.Toggle style={{ backgroundColor:"transparent",border:  navbar ?"1px solid white":"1px solid var(--second-color)" ,color: navbar ?"white" : "black" ,padding:"8px"}} variant="success" id="dropdown-basic">
                  {marketName === "" ? (
                    <div className="d-flex align-items-center justify-content-between">
                    <img
                      style={{
                        width: "30px",
                        height: "20px",
                          borderRadius: "5px",
                        marginLeft:"5px"
                      }}
                      src={  marketInfo[0]?.image}
                      alt=""
                    />
                    <p style={{ margin: "0" }}> { marketInfo[0]?.name}</p>
                  </div>
                  
                  ) : (
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        style={{
                          width: "30px",
                          height: "20px",
                            borderRadius: "5px",
                          marginLeft:"5px"
                        }}
                        src={marketName.image}
                        alt=""
                      />
                      <p style={{ margin: "0" }}> {marketName.name}</p>
                    </div>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {marketInfo.map((item) => (
                    <Dropdown.Item key={item.id} style={{ margin: "5px 0" }}
                    onClick={()=>handleMarketChange(item.id)}>
                      <div
                        onClick={() => setMarketName(item)}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "20px",
                            borderRadius: "5px",
                          }}
                          src={item.image}
                          alt=""
                        />
                        <p style={{ margin: "0" }}> {item.name}</p>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              <button onClick={handleShow} className="btn-login me-3">
                <FaUserAlt className="ms-2" />
                تسجيل دخول
              </button>
              <Modall
                show={show}
                setShow={setShow}
                setIsLoggedIn={setIsLoggedIn}
                type="1"
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarLogin;
