import React, { useEffect, useState } from "react";
import { IoIosCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import logoHeaderImg from "../../../assests/black-logo.png";
import "./HeadBar.css";
import Marquee from "react-fast-marquee";
import  axios  from 'axios';

function HeadBar() {
  const [newsData, setNewsData] = useState([]);

  const marketId = localStorage.getItem("thiqa-marketId")

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/news/${marketId}`,
        headers: "application/json",
      });
      setNewsData(result.data.data);
    })();
  }, []);

  return (
    <div className="headbar">
      <Marquee className="marq" speed={80}>
        {newsData.map((item) => (
          <div key={item.id}>
            <p>{item.title}</p>
          </div>
        ))}
      </Marquee>
    </div>
  );
}

export default HeadBar;
