import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Packages.css";
import { FcPackage } from "react-icons/fc";
import axios from "axios";
import { Slide, LightSpeed } from "react-reveal";
import Modall from "../../Components/Modal/Modal";

function Packages({ setIsLoggedIn}) {
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const marketId = localStorage.getItem("thiqa-marketId")

  const [dataPckages, setDataPckages] = useState([]);

  const token = localStorage.getItem("thiqa-userToken");
  
 

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/package/index/${marketId}`,
        headers: "application/json",
      });
      setDataPckages(result.data.data);
       setLoading(false);
    })();
  }, [marketId]);

  return (
    <>
      <div className="packages-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>الباقات</span>
          </h5>
        </div>
      </div>
      <div className="packages">
        <Container className="container-body package-content">
          <LightSpeed>
            <h4>الباقات</h4>
          </LightSpeed>
          {loading ? (
            <div className="loading-carsoul loading text-center">
              <div className="spinner-grow text-dark" role="status"></div>
              <div className="spinner-grow text-warning" role="status"></div>
              <div className="spinner-grow text-dark" role="status"></div>
            </div>
          ) : (
            <Row className="d-flex justify-content-around align-items-center">
              {dataPckages.map((item) => (
                <Col xs={12} md={3} key={item.id}>
                  <Slide right>
                    <>
                      <div
                        className="card flex-column packages-card"
                        style={{
                          padding: "0",
                          backgroundColor: "var(--second-color)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1440 320"
                        >
                          <path
                            fill=" #f7f7f7"
                            fill-opacity="1"
                            d="M0,96L48,85.3C96,75,192,53,288,80C384,107,480,181,576,218.7C672,256,768,256,864,234.7C960,213,1056,171,1152,165.3C1248,160,1344,192,1392,208L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                          ></path>
                        </svg>

                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex align-items-center">
                            <FcPackage className="icon-package" />
                            <h3>{item.name}</h3>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#f7f7f7",
                            width: "70%",
                            marginLeft: "30%",
                            borderRadius: " 60px 0 0 60px",
                            padding: "30px 0",
                            textAlign: "center",
                          }}
                        >
                          <h5>{item.price} ريال</h5>
                        </div>

                        <h6>{item.days} يوم</h6>
                        <h6>{item.description}</h6>
                        {token ? null : (
                          <button onClick={() => setShow(true)}>اشترك</button>
                        )}
                      </div>
                    </>
                  </Slide>
                </Col>
              ))}
            </Row>
          )}
          <Modall
            show={show}
            setShow={setShow}
            setIsLoggedIn={setIsLoggedIn}
            type="1"
          />
        </Container>
      </div>
    </>
  );
}

export default Packages;
