import React, { useEffect, useState } from "react";
import AdvantageTrust from "../../Components/AdvantageTrust/AdvantageTrust";
import Carosel from "../../Components/Carosel/Carosel";
import FreeLogin from "../../Components/FreeLogin/FreeLogin";
import RegisterSteps from "../../Components/RegisterSteps/RegisterSteps";
import ServicesComp from "../../Components/ServicesComp/ServicesComp";
import TrustInfo from "../../Components/TrustInfo/TrustInfo";
import ContactComp from "../../Components/ContactComp/ContactComp";
import Recommendations from "../../Components/Recommendations/Recommendations";
import NewsComp from "../../Components/NewsComp/NewsComp";
import { BackTop } from "antd";
import "./Main.css";
import { AiOutlineToTop } from "react-icons/ai";
import Pointer from "../../Components/Pointer/Pointer";


function Main() {
  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: 4,
    backgroundColor: "var(--second-color)",
    color: "var(--main-color)",
    textAlign: "center",
    fontSize: 38,
    border: "1px solid var(--main-color)",
  };

  const [showBtn, setShowBtn] = useState(false);

  const handleBckToTop = () => {
    if (window.scrollY >= 250) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  useEffect(() => {
    handleBckToTop();
    window.addEventListener("scroll", handleBckToTop);
  }, []);

  return (
    <>
      <Carosel />
      <FreeLogin />
      <ServicesComp />
      <RegisterSteps />
      <AdvantageTrust />
      <TrustInfo />
      <Pointer/>
      <Recommendations />
      <ContactComp />
      <NewsComp />
      <BackTop
        className={showBtn ? "back-top-icon show" : "back-top-icon hide"}
      >
        <div style={style}>
          <AiOutlineToTop />
        </div>
      </BackTop>
    </>
  );
}

export default Main;
