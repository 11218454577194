import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./RegisterSteps.css";
import { GiNotebook } from "react-icons/gi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { Slide } from "react-reveal";

function RegisterSteps() {
  return (
    <div className="register-steps">
      <div className="transperant-bg">
        <Container>
          <h5>خطوات التسجيل</h5>
          <Slide right>
            <Row style={{ paddingBottom: "20px" }}>
              <Col className="col-steps">
                <div className="icon-div">
                  <GiNotebook className="step-icon" />
                </div>
                <h6>بيانات التسجيل</h6>
              </Col>
              <Col className="col-steps">
                <div className="icon-div">
                  <BsFillChatDotsFill className="step-icon" />
                </div>
                <h6>التواصل المباشر مع المستشار الخاص</h6>
              </Col>
              <Col className="col-steps diff">
                <div className="icon-div">
                  <FaMoneyBillAlt className="step-icon" />
                </div>
                <h6>استمتع بفترة مجانية مميزة</h6>
              </Col>
            </Row>
          </Slide>
        </Container>
      </div>
    </div>
  );
}

export default RegisterSteps;
