import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./TrustRate.css";
import { Slide, LightSpeed } from "react-reveal";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "antd";
import Collapse from 'react-bootstrap/Collapse';

function Rating() {
  const [rateForm, setRateForm] = useState({
    name: "",
    advisor_name: "",
    evaluation_advisor: "",
    evaluating_advisor_time: "",
    evaluate_performance_recommendations: "",
    recommendation_success_rate: "",
    notes: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleRateChange = (e) => {
    setRateForm({ ...rateForm, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleRateSubmit = () => {
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/evaluation/store`, rateForm)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setRateForm({
          name: "",
          advisor_name: "",
          evaluation_advisor: "",
          evaluating_advisor_time: "",
          evaluate_performance_recommendations: "",
          recommendation_success_rate: "",
          notes: "",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };


  return (
    <>
      <div className="trust-rate-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>نسبة ثقة</span>
          </h5>
        </div>
      </div>
      <div className="trust-rate">
        <Container className="container-body trust-rate-content">
          <LightSpeed>
            <h4>مؤسسة ثقة</h4>
          </LightSpeed>
          <Row>
            <Col xs={12}>
              <Slide right>
                <div className="card flex-column justify-content-center align-items-start ps-4 pe-4">
                  <h6>اسم العميل</h6>
                  <input
                    className="input-rate"
                    type="text"
                    placeholder="اسم العميل"
                    onChange={handleRateChange}
                    name="name"
                    value={rateForm.name}
                  />
                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div className="card flex-column justify-content-center align-items-start ps-4 pe-4">
                  <h6>اسم المستشار الخاص بك</h6>
                  <input
                    className="input-rate"
                    type="text"
                    placeholder="اسم المستشار"
                    onChange={handleRateChange}
                    name="advisor_name"
                    value={rateForm.advisor_name}
                  />
                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div
                  className="card flex-column justify-content-center align-items-start ps-4 pe-4"
                  value={rateForm.evaluation_advisor}
                  onChange={handleRateChange}
                >
                  <h6>تقييم أداء المستشار من حيث المتابعة</h6>
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluation_advisor"
                      value="ممتاز"
                      id="flexRadioDefault1"
                      checked={rateForm.evaluation_advisor === "ممتاز"}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      ممتاز
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluation_advisor"
                      id="flexRadioDefault2"
                      value="جيد جدا"
                      checked={rateForm.evaluation_advisor === "جيد جدا"}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      جيد جدا
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluation_advisor"
                      id="flexRadioDefault3"
                      value="جيد"
                      checked={rateForm.evaluation_advisor === "جيد"}
                    />
                    <label className="form-check-label" for="flexRadioDefault3">
                      جيد
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluation_advisor"
                      id="flexRadioDefault4"
                      value="مقبول"
                      checked={rateForm.evaluation_advisor === "مقبول"}
                    />
                    <label className="form-check-label" for="flexRadioDefault4">
                      مقبول
                    </label>
                  </div>
                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div
                  className="card flex-column justify-content-center align-items-start ps-4 pe-4"
                  value={rateForm.evaluating_advisor_time}
                  onChange={handleRateChange}
                >
                  <h6>تقييم اداء المستشار من حيث معاد ارسال التوصية</h6>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluating_advisor_time"
                      id="flexRadioDefault5"
                      value="ارسال التوصية فى الوقت المناسب"
                      checked={
                        rateForm.evaluating_advisor_time ===
                        "ارسال التوصية فى الوقت المناسب"
                      }
                    />
                    <label className="form-check-label" for="flexRadioDefault5">
                      ارسال التوصية فى الوقت المناسب
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluating_advisor_time"
                      id="flexRadioDefault6"
                      value="ارسال التوصية متأخرة"
                      checked={
                        rateForm.evaluating_advisor_time ===
                        "ارسال التوصية متأخرة"
                      }
                    />
                    <label className="form-check-label" for="flexRadioDefault6">
                      ارسال التوصية متأخرة
                    </label>
                  </div>
                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div
                  className="card flex-column justify-content-center align-items-start ps-4 pe-4"
                  value={rateForm.evaluate_performance_recommendations}
                  onChange={handleRateChange}
                >
                  <h6>تقييم اداء التوصيات</h6>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluate_performance_recommendations"
                      id="flexRadioDefault7"
                      value="ممتاز"
                      checked={
                        rateForm.evaluate_performance_recommendations ===
                        "ممتاز"
                      }
                    />
                    <label className="form-check-label" for="flexRadioDefault7">
                      ممتاز
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluate_performance_recommendations"
                      id="flexRadioDefault8"
                      value="جيد جدا"
                      checked={
                        rateForm.evaluate_performance_recommendations ===
                        "جيد جدا"
                      }
                    />
                    <label className="form-check-label" for="flexRadioDefault8">
                      جيد جدا
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="evaluate_performance_recommendations"
                      id="flexRadioDefault9"
                      value="جيد"
                      checked={
                        rateForm.evaluate_performance_recommendations === "جيد"
                      }
                    />
                    <label className="form-check-label" for="flexRadioDefault9">
                      جيد
                    </label>
                  </div>
                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div
                  className="card flex-column justify-content-center align-items-start ps-4 pe-4"
                  onChange={handleRateChange}
                  value={rateForm.recommendation_success_rate}
                >
                  <h6>نسبة نجاح التوصية</h6>
                  <div className="form-check" onClick={() => setOpen(false)}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recommendation_success_rate"
                      id="flexRadioDefault11"
                      value="100%"
                      checked={rateForm.recommendation_success_rate === "100%"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault11"
                    >
                      100%
                    </label>
                  </div>
                  <div className="form-check" onClick={() => setOpen(false)}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recommendation_success_rate"
                      id="flexRadioDefault12"
                      value="90%"
                      checked={rateForm.recommendation_success_rate === "90%"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault12"
                    >
                      90%
                    </label>
                  </div>
                  <div className="form-check" onClick={() => setOpen(false)}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recommendation_success_rate"
                      id="flexRadioDefault13"
                      value="70%"
                      checked={rateForm.recommendation_success_rate === "70%"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault13"
                    >
                      70%
                    </label>
                  </div>
                  <div className="form-check" onClick={() => setOpen(false)}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recommendation_success_rate"
                      id="flexRadioDefault14"
                      value="50%"
                      checked={rateForm.recommendation_success_rate === "50%"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault14"
                    >
                      50%
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="form-check" onClick={() => setOpen(true)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="recommendation_success_rate"
                        id="flexRadioDefault199"
                        value=""
                        checked={rateForm.recommendation_success_rate === ""}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault199"
                      >
                        اخري
                      </label>
                    </div>



                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <input className="other" type="number" name="recommendation_success_rate" value={rateForm.recommendation_success_rate} onChange={handleRateChange} />
                      </div>
                    </Collapse>

                  </div>

                </div>
              </Slide>
            </Col>
            <Col xs={12}>
              <Slide right>
                <div className="card flex-column justify-content-center align-items-start ps-4 pe-4">
                  <h6>ملاحظات</h6>
                  <input
                    className="input-rate"
                    type="text"
                    placeholder="ملاحظات"
                    onChange={handleRateChange}
                    name="notes"
                    value={rateForm.notes}
                  />
                </div>
              </Slide>
            </Col>
          </Row>
          <Button
            htmlType="submit"
            loading={submitting}
            onClick={handleRateSubmit}
          >
            {!submitting && "تأكيد"}
          </Button>
        </Container>
      </div>
    </>
  );
}

export default Rating;
