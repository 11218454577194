import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsFillFolderFill } from "react-icons/bs";
import { IoIosPaper } from "react-icons/io";
import { IoIosPerson } from "react-icons/io";
import "./TrustInfo.css"
import AnimatedNumber from "react-animated-number";
import axios from 'axios';
import { Slide } from "react-reveal";


function TrustInfo() {

      const [dataCounts, setDataCounts] = useState({});

      useEffect(() => {
        (async () => {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/homepage/counts`,
            headers: "application/json",
          });
          setDataCounts(result.data.data);
        })();
      }, []);

  return (
    <div className="trust-info">
      <div style={{ padding: "50px" }} className="transperant-bg">
        <Container>
          <Slide right>
            <Row>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-center info-trust-mob"
              >
                <BsFillFolderFill className="trust-info-icon" />
                <div>
                  <h5>عدد الأسهم</h5>
                  <h6>
                    <AnimatedNumber
                      value={dataCounts.sectors}
                      style={{
                        fontSize: 30,
                        color: "var(--main-color)",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      duration={4000}
                      formatValue={(n) => n.toFixed(0)}
                      frameStyle={(percentage) =>
                        percentage > 20 && percentage < 80
                          ? { opacity: 0.5 }
                          : {}
                      }
                    />
                  </h6>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-center info-trust-mob"
              >
                <IoIosPaper className="trust-info-icon" />
                <div>
                  <h5>عدد التوصيات</h5>
                  <h6>
                    <AnimatedNumber
                      value={dataCounts.recommendation}
                      style={{
                        fontSize: 30,
                        color: "var(--main-color)",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      duration={4000}
                      formatValue={(n) => n.toFixed(0)}
                      frameStyle={(percentage) =>
                        percentage > 20 && percentage < 80
                          ? { opacity: 0.5 }
                          : {}
                      }
                    />
                  </h6>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-center info-trust-mob"
              >
                <IoIosPerson className="trust-info-icon" />
                <div>
                  <h5>عدد العملاء</h5>
                  <h6>
                    <AnimatedNumber
                      value={dataCounts.clients}
                      style={{
                        fontSize: 30,
                        color: "var(--main-color)",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      duration={4000}
                      formatValue={(n) => n.toFixed(0)}
                      frameStyle={(percentage) =>
                        percentage > 20 && percentage < 80
                          ? { opacity: 0.5 }
                          : {}
                      }
                    />
                  </h6>
                </div>
              </Col>
            </Row>
          </Slide>
        </Container>
      </div>
    </div>
  );
}

export default TrustInfo