import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./ServicesComp.css";
import axios from "axios";
import { Slide, LightSpeed } from "react-reveal";

function ServicesComp() {
  const [dataServices, setDataServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/services`,
        headers: "application/json",
      });

      const iconsArr = [
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
      ];

      const arrNew = result.data.data.map((object, i) => {
        return { ...object, icon: iconsArr[i] };
      });
      setDataServices(arrNew);

      setLoading(false);
    })();
  }, []);

  return (
    <div className="services">
      <Container>
        <LightSpeed>
          <h4>الخدمات</h4>
        </LightSpeed>
        {loading ? (
          <div className="loading text-center">
            <div className="spinner-grow text-dark" role="status"></div>
            <div className="spinner-grow text-warning" role="status"></div>
            <div className="spinner-grow text-dark" role="status"></div>
          </div>
        ) : (
          <Row>
            {dataServices.map((item) => (
              <Col xs={12} md={6} lg={4} key={item.id}>
                <Slide right>
                  <div className="card services-card">
                    <i className={`${item.icon} advantage-icon`}></i>

                    <div>
                      <h6>{item.name}</h6>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </Slide>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
}

export default ServicesComp;
