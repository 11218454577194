import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./News.css";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination/Pagination";
import axios from "axios";
import { FcCalendar } from "react-icons/fc";
import { Slide,Fade, LightSpeed } from "react-reveal";
import { HiArrowSmRight } from "react-icons/hi";

function News() {
  const [allNewsData, setAllNewsData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ year: "", month: "", type: "" });
  
  const marketId = localStorage.getItem("thiqa-marketId")

  const getNews = async () => {
    setLoading(true);
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/news/index/${marketId}`,
      headers: "application/json",
    });
    setAllNewsData(result.data.data.data);
    setTotalCount(result.data.data.total);
    setPerPage(result.data.data.per_page)
    setLoading(false);
  };

  useEffect(() => {
    getNews();
  }, [marketId]);

  const getPage = async (page) => {
    const result1 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/news/index?page=${page}`
    );
    setAllNewsData(result1.data.data.data);
    setTotalCount(result1.data.data.total);
    setPerPage(result1.data.data.per_page)
  };

  const getSearch = async (filter) => {
    if (filter.year === "" && filter.month === ""&& filter.type === "") {
      getNews();
    } else {
      setLoading(true);
      const result2 = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/news/search/${marketId}?year=${filter.year}&month=${filter.month}&type=${filter.type}`
      );
      setAllNewsData(result2.data.data.data);
      setTotalCount(result2.data.data.total);
      setPerPage(result2.data.data.per_page)
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="news-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>الأخبار</span>
          </h5>
        </div>
      </div>
      <div className="news">
        <Container className="container-body news-content">
          <LightSpeed>
            <h4>الأخبار</h4>
          </LightSpeed>
          <h5>اختر تاريخ البحث</h5>
          <Row className="align-items-center justify-content-between mb-5 mt-4">
            <Col md={3}>
              <select
                value={filter.year}
                name="year"
                onChange={handleFilterChange}
              >
                <option value="">العام-الكل</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </Col>
            <Col md={3}>
              <select
                value={filter.month}
                name="month"
                onChange={handleFilterChange}
              >
                <option value="">الشهر-الكل</option>
                <option value="1">يناير</option>
                <option value="2">فبراير</option>
                <option value="3">مارس</option>
                <option value="4">ابريل</option>
                <option value="5">مايو</option>
                <option value="6">يونيه</option>
                <option value="7">يوليو</option>
                <option value="8">اغسطس</option>
                <option value="9">سبتمبر</option>
                <option value="10">اكتوبر</option>
                <option value="11">نوفمبر</option>
                <option value="12">ديسمبر</option>
              </select>
            </Col>
            <Col md={3}>
              <select
                value={filter.type}
                name="type"
                onChange={handleFilterChange}
              >
                <option value="">النوع-الكل</option>
                <option value="1">الاخبار</option>
                <option value="2">اكتتاب</option>
              </select>
            </Col>
            <Col md={3}>
              <button onClick={() => getSearch(filter)}>بحث</button>
            </Col>
          </Row>
          {loading ? (
            <div className="loading text-center">
              <div className="spinner-grow text-dark" role="status"></div>
              <div className="spinner-grow text-warning" role="status"></div>
              <div className="spinner-grow text-dark" role="status"></div>
            </div>
          ) : (
            <>
              <Row className="row-news">
                {allNewsData.length !== 0 ? (
                  allNewsData.map((item) => (
                    <Col xs={12} md={3} key={item.id}>
                      <Slide right>
                        <Card
                          style={{ height: "440px" }}
                          className="d-flex flex-column mb-4 news-card"
                        >
                          <div style={{ position: "relative" }}>
                            <Card.Img
                              style={{ minHeight: "283px" }}
                              variant="top"
                              src={item.image}
                            />
                            <p className="date-news">
                              <FcCalendar style={{ marginLeft: "5px" }} />
                              {new Date(item.created_at).toLocaleDateString(
                                "ar-EG",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </p>
                          </div>
                          <Card.Body style={{ lineBreak: "anywhere" }}>
                            <Card.Title style={{ fontWeight: "bold" }}>
                              <p>{item.title}</p>
                            </Card.Title>
                            <Card.Text>
                              <p>{item.description}</p>
                            </Card.Text>
                          </Card.Body>
                          <Card.ImgOverlay className="over-lay">
                            <Fade>
                              <div className="card-overlay">
                                <Link
                                  style={{ color: "var(--second-color)" }}
                                  to={`/news-details/${item.id}`}
                                >
                                  <div
                                    onClick={() =>
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      })
                                    }
                                    className="arrow-icon-div"
                                  >
                                    <HiArrowSmRight className="arrow-icon" />
                                  </div>
                                </Link>
                              </div>
                            </Fade>
                          </Card.ImgOverlay>
                        </Card>
                      </Slide>
                    </Col>
                  ))
                ) : (
                  <p className="no-news">لاتوجد اخبار</p>
                )}
                </Row>
                {totalCount > perPage && (
            <Pagination
              getPage={getPage}
              totalCount={totalCount}
              perPage={perPage}
            />
          )}
          
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default News;
