import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from "axios";

function FreeLoginModal({ show3, setShow3 }) {
  const [freeForm, setFreeForm] = useState({
    name: "",
    email: "",
    phone: "",
    cash_type: "",
    market_id: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [marketInfo, setMarketInfo] = useState([]);


  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/markets`,
        headers: "application/json",
      });
      const thiqaMarkets = result.data.data.filter((item) => item.web_id === 1);

      setMarketInfo(thiqaMarkets);
    })();
  }, []);

  const handleClose = () => setShow3(false);

  const handleChange = (e) => {
    setFreeForm({ ...freeForm, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        {/* <AiFillCheckCircle style={{ color: "#07bc0c", fontSize: "28px" }} /> */}
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        {/* <RiErrorWarningFill style={{ color: "#e74c3c", fontSize: "28px" }} /> */}
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleFreeRegister = () => {
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/auth/free-registration`,
        freeForm
      )
      .then((res) => {
        console.log(res);
        setSubmitting(false);
        successNotify(res.data.message);
        setFreeForm({
          name: "",
          email: "",
          phone: "",
          cash_type: "",
          market_id: "",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error?.response?.data?.message);
        ErrorNotify(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "9999" }}
        className="PackageModal"
        show={show3}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>تسجيل مجاني</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="freelogin-sec">
            <h5>تسجيل مجاني</h5>
            <div className="freelogin-inputs">
              <h6>يمكنك الإستفادة من خدمات ثقة مجانا</h6>
              <input
                name="name"
                value={freeForm.name}
                onChange={handleChange}
                type="text"
                placeholder="الاسم"
              />
              <input
                value={freeForm.email}
                name="email"
                onChange={handleChange}
                type="email"
                placeholder="البريد الالكتروني"
              />
              <input
                value={freeForm.phone}
                name="phone"
                onChange={handleChange}
                type="number"
                placeholder="رقم الجوال"
              />
              <Row style={{ margin: "12px 0" }}>
                <Col xs={12} md={6} className="my-3">
                  <select
                    name="cash_type"
                    value={freeForm.cash_type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      اختر السيوله
                    </option>
                    <option value="1">من ٢٠ ال ٥٠</option>
                    <option value="2">من ٥٠ ال ٥٠٠</option>
                    <option value="3">من ٥٠٠ ال مافوق</option>
                    <option value="4">آخري</option>
                  </select>
                </Col>
                <Col xs={12} md={6} className="my-3">
                  <select
                    name="market_id"
                    value={freeForm.market_id}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      اختر السوق
                    </option>
                    {marketInfo.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleFreeRegister}
                type="button"
              >
                {!submitting && "تسجيل"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FreeLoginModal;
