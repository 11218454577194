import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServicesComp from '../../Components/ServicesComp/ServicesComp';
import "./Services.css"

function Services() {

  return (
    <>
      <div className="services-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>الخدمات</span>
          </h5>
        </div>
      </div>
      <div className="services">
        <Container className="container-body">
          <ServicesComp />
        </Container>
      </div>
    </>
  );
}

export default Services