import React, { useState } from "react";
import "./Training.css";
import { Container, Row, Col } from "react-bootstrap";
import { MdDone } from "react-icons/md";
import { MdOutlineStar } from "react-icons/md";
import { Link } from "react-router-dom";
import { Slide, LightSpeed } from "react-reveal";
import TrainingModal from "../../Components/TrainingModal/TrainingModal";

function Training({ setIsLoggedIn }) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const token = localStorage.getItem("thiqa-userToken");

  return (
    <>
      <div className="training-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>تدريب</span>
          </h5>
        </div>
      </div>
      <div className="training">
        <Container>
          <LightSpeed>
            <h3>برامج التدريب</h3>
          </LightSpeed>

          <Row>
            <Col md={6}>
              <Slide right>
                <div className="card training-card flex-column align-items-start">
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--second-color)",
                      padding: "10px 0",
                    }}
                    className="text-center h-training"
                  >
                    <h4>كورس التحليل الفني المستوى الأول</h4>
                  </div>
                  <div style={{ width: "100%" }}>
                    <h5>محاور الدورة :</h5>
                    <p>
                      <MdDone className="done-course" />
                      تعريف علم التحليل الفني
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة سيكولوجية المتداول
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      تعريف أنواع الرسم البياني
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة الرسم البياني العمودي
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة أنواع الدعم والمقاومة
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة أحجام التداول وتأثيرها على الاتجاه
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة بعض النماذج الاستمرارية و الانعكاسية
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      دراسة خطوط الاتجاه
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      تعريف أنواع اتجاهات الاسواق
                    </p>
                    <hr />
                    <div style={{ width: "100%" }}>
                      <h5>الفئة المستهدفة:</h5>
                      <p className="m-0">
                        <MdOutlineStar className="done-course" />
                        المستثمرين في البورصـات
                      </p>
                      <hr />
                      <p className="m-0">
                        <MdOutlineStar className="done-course" />
                        الطلبة والخريجين الراغبين في العمل في شركات تداول أوراق
                        مالية
                      </p>
                      {token ? null : (
                        <>
                          <hr />
                          <div className="d-flex justify-content-center align-items-center">
                            <button onClick={handleShow}>اشترك</button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Slide>
            </Col>

            <Col md={6}>
              <Slide right>
                <div className="card training-card flex-column align-items-start">
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--second-color)",
                      padding: "10px 0",
                    }}
                    className="text-center h-training"
                  >
                    <h4>كورس التحليل الفني الشامل</h4>
                  </div>
                  <div style={{ width: "100%" }}>
                    <h5>محاور الدورة :</h5>
                    <p>
                      <MdDone className="done-course" />
                      دراسة سيكولوجية المتداول وأنواع المتداولين. الشموع
                      اليابانية
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      تعريف علم التحليل الفني ومحاوره
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      الفجوات السعرية
                    </p>
                    <hr />

                    <p>
                      <MdDone className="done-course" />
                      اتجاهات أسواق المال وكيفية التعرف عليها
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      أنواع الرسم البياني
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      خطوط الاتجاة والقنوات السعرية
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      النماذج الاستمرارية والانعكاسية لحركة الأسعار
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      أهمية دراسة التحليل الموجي
                    </p>
                    <hr />
                    <p>
                      <MdDone className="done-course" />
                      نظرة عامة عن موجات اليوت
                    </p>
                    <hr />
                    <div style={{ width: "100%" }}>
                      <h5>أشهر النماذج السعرية :</h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج القمة المزدوجة
                        </p>
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج الرأس والكتفين
                        </p>
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج القمة الثلاثية
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج القاع المزدوج
                        </p>
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج الرأس المقلوب
                        </p>
                        <p className="m-0">
                          <MdOutlineStar className="done-course" />
                          نموذج القاع الثلاثي
                        </p>
                      </div>

                      {token ? null : (
                        <>
                          <hr />
                          <div className="d-flex justify-content-center align-items-center">
                            <button onClick={handleShow}>اشترك</button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Slide>
            </Col>
          </Row>

          <TrainingModal
            show={show}
            setShow={setShow}
            setIsLoggedIn={setIsLoggedIn}
            type="3"
          />
        </Container>
      </div>
    </>
  );
}

export default Training;
