import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import logo from "../../../assests/black-logo.png";
import logo2 from "../../../assests/black-logo2.png";
import "./NavBarr.css";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { MdMenu } from "react-icons/md";
import axios from "axios";
import DarkModeButton from "../DarkModeButton/DarkModeButton";

function NavBarr({ setIsLoggedIn , theme, setTheme}) {
  const [selected, setSelected] = useState();
  const [marketInfo, setMarketInfo] = useState([]);
  const [activeLink, setActiveLink] = useState(1);
  
     const [navbar, setNavbar] = useState(false);

  const [hide, setHide] = useState(true);

  const handleHide = () => setHide(!hide);

  const handleClickToggle = () => {
    setHide(!hide);
  };

  const handleMarketChange = (e) => {
    setSelected(e.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(localStorage.getItem("thiqa-userId"));
  };

    const changeNavColor = () => {
      if (window.scrollY >= 80) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };
    useEffect(() => {
      changeNavColor();
      window.addEventListener("scroll", changeNavColor);
    });

  const user = localStorage.getItem("thiqa-userName");

  const userImg = localStorage.getItem("thiqa-userImg");

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/markets`,
        headers: "application/json",
      });

      setMarketInfo(result.data.data);
      setSelected(result.data.data[0]?.id);
    })();
  }, []);

  return (
    <Navbar
      style={{ backgroundColor: navbar ? "#090b28" : "white" }}
      className={navbar ? "navbar-trust" : "navbar-trust  change-links"}
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container>
        <Link
          className="logo-link"
          to="/"
          onClick={() => {
            setHide(!hide);
            setActiveLink(1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img className="logo" src={navbar ? logo : logo2} alt="" />
        </Link>
        <MdMenu onClick={handleClickToggle} className="toggle-btn" />
        <Navbar.Collapse className={hide ? "hide" : "show"}>
          <Nav className="links-route">
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 1 ? "active-link" : "links"}
                to="/"
              >
                الرئيسية
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(2);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 2 ? "active-link" : "links"}
                to="/packges"
              >
                الباقات
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(3);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 3 ? "active-link" : "links"}
                to="/performance-rate"
              >
                معدل الأداء
              </Link>
            </div>
            {/* <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(4);
                }}
                className={activeLink === 4 ? "active-link" : "links"}
                to="/your-issue"
              >
                مشكلتك
              </Link>
            </div> */}
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(5);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 5 ? "active-link" : "links"}
                to="/trust-rate"
              >
                نسبة ثقة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(6);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 6 ? "active-link" : "links"}
                to="/services"
              >
                الخدمات
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(7);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 7 ? "active-link" : "links"}
                to="/news"
              >
                الاخبار
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(8);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 8 ? "active-link" : "links"}
                to="/about-us"
              >
                عن ثقة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(4);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 4 ? "active-link" : "links"}
                to="/training"
              >
                تدريب
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(9);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 9 ? "active-link" : "links"}
                to="/competition"
              >
                المنافسة
              </Link>
            </div>
            <div>
              <Link
                onClick={() => {
                  setHide(!hide);
                  setActiveLink(10);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={activeLink === 10 ? "active-link" : "links"}
                to="/contact-us"
              >
                اتصل بنا
              </Link>
            </div>
          </Nav>
          <Nav>
            <div className="flags ms-3">
              {/* <select
                name=""
                id=""
                value={selected}
                onChange={handleMarketChange}
                n
              >
                {marketInfo.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select> */}
            </div>

            <div>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-user">
                  <img className="topbarImg" src={userImg} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu">
                  <div className="text-center">
                    <h6
                      style={{
                        color: "var(--second-color)",
                        padding: "10px 5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {user}
                    </h6>
                  </div>
                  <div className="text-center">
                    <DarkModeButton theme={theme} setTheme={setTheme} />
                  </div>
                  <Dropdown.Item className="dropdown-items">
                    <button className="logout-btn" onClick={handleLogout}>
                      <BiLogOut className="mx-1" />
                      تسجيل خروج
                    </button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarr;
