import React, { useEffect } from "react";
import Switch from "react-switch";
import { HiMoon } from "react-icons/hi";
import { BsSunFill } from "react-icons/bs";

function DarkModeButton({theme ,setTheme}) {
 
 

  const handleTheme = (value) => {
    setTheme(value ? "dark" : "light" );

  };

  useEffect(() => {
    localStorage.setItem("mode-color", theme);
  }, [theme])
  

  return (
    <div>
      <Switch
        checked={theme === "dark"}
        onChange={handleTheme}
        onColor="#00BFFF"
        offColor="#333"
        uncheckedIcon={<HiMoon style={{ color: "white", marginLeft: "8px" }} />}
        checkedIcon={
          <BsSunFill style={{ color: "yellow", marginLeft: "8px" }} />
        }
      />
      <br />
      <br />
    </div>
  );
}

export default DarkModeButton;
