import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import footerLogo from "../../../assests/black-logo.png";
import { IoIosCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import "./Footer.css";
import axios from "axios";
import Parse from "html-react-parser";
import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";

function Footer() {
  const [dataInfo, setDataInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/about-as`,
        headers: "application/json",
      });
      setDataInfo(result.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/content/contacts`,
        headers: "application/json",
      });
      setContactInfo(result.data.data);
    })();
  }, []);


  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <h5>عن ثقة</h5>
            <p>{Parse(`${dataInfo[0]}`)}</p>
          </Col>
          <Col md={4}>
            <h3>روابط سريعة</h3>
            <div className="icons-trust-footer">
              <Link to="/" className="footer-links">
                الرئيسية
              </Link>
              <Link to="/about-us" className="footer-links">
                عن ثقة
              </Link>
              <Link to="/services" className="footer-links">
                الخدمات
              </Link>
              <Link to="/contact-us" className="footer-links">
                اتصل بنا
              </Link>
            </div>
          </Col>
          <Col md={4} className="footer-img">
            <img className="mb-3" src={footerLogo} alt="" />
            <div className="d-flex justify-content-center align-items-center my-3">
              <h6>{contactInfo.phone}</h6>
              <IoIosCall className="information-icons" />
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <h6>{contactInfo.email}</h6>
              <FaEnvelope className="information-icons2" />
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <h6>{contactInfo.address}</h6>
              <MdLocationOn className="information-icons" />
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <a href={contactInfo.instagram} target="_blank" rel="noreferrer">
                <div className="social-icon-parent">
                  <RiInstagramFill className="social-icon" />
                </div>
              </a>
              <a href={contactInfo.twitter} target="_blank" rel="noreferrer">
              <div className="social-icon-parent">
                <FaTwitter className="social-icon" />
                </div>
                </a>
                <a href={contactInfo.facebook} target="_blank" rel="noreferrer">
              <div className="social-icon-parent">
                <FaFacebookF className="social-icon" />
                </div>
                </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
